import Store from '../store/index'
import { app } from '@/constants/app'
import { getExamPreferencesKey } from '@/constants/examPreferences.js'
import { getFeaturesFlagKey } from '@/constants/features_flag.js'

export default {
  checkTva,
  checkRib,
  haveAccess,
  refreshFilters,
  ageCategory,
  dateForConsultation,
  isCompatibleVersion,
  uptimeColor,
  lastSeenColor,
  hasSetting,
  hasFeatureFlag,
  hasRole,
  colorCodeVersionBox,
  showLastName,
  showFirstname,
  showAgeOrBirthdate,
  showPatientNumber,
  showPhone,
  showInitials,
  showFileNumber,
  hasExamPref,
  hasFF
}

function checkTva(tva) {
  return !!tva.match('^[A-Z]{2}')
}

function checkRib(rib) {
  const iban = rib.iban.match('^[A-Z]{2}[0-9]{2}[0-9]{5}[0-9]{5}[0-9A-Z]{11}[0-9]{2}$')
  if (!iban) {
    return false
  }

  const bic = rib.bic.match('^[A-Z]{4}[A-Z]{2}[A-Z]{2}(([A-Z0-9]{3})|([A-Z0-9]{0}))$')

  if (!bic) return false

  return true
}

function haveAccess(road) {
  const access = Store.getters['utils/getNav'].find(access => {
    return access.road === road
  })

  return !!access
}

function refreshFilters() {
  document.location.reload(true)
}

function ageCategory(age) {
  const ageCat = [5, 10, 20, 30, 40, 50, 60, 80]
  for (let i = 0; i < ageCat.length; i++) {
    if (age < ageCat[i]) return i
  }
  return 8
}

function dateForConsultation() {
  let today = new Date()

  let month = today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1
  let hours = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
  let minutes = today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
  let seconds = today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds()
  let day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate()

  let date = today.getFullYear() + '-' + month + '-' + day
  let time = hours + ':' + minutes + ':' + seconds

  return date + ' ' + time
}

function isCompatibleVersion(apiVersion) {
  return app.frontversion.slice(0, 3) == apiVersion.slice(0, 3) ? true : false
}

function uptimeColor(date) {
  let color

  if (date >= 86400) {
    color = 'red'
  }
  if (date >= 0 && date <= 86400) {
    color = 'orange'
  }
  if (date == null) {
    color = 'white'
  }
  return color
}

function lastSeenColor(date) {
  let color

  if (date >= 86400) {
    color = 'red'
  }
  if (date >= 0 && date <= 14400) {
    color = 'green'
  }
  if (date >= 14400 && date <= 86400) {
    color = 'orange'
  }

  return color
}

function hasSetting(setting) {
  for (let key in Store.getters['authentification/getSettings']) {
    if (Store.getters['authentification/getSettings'][key].type == setting) {
      if (Store.getters['authentification/getSettings'][key].value == 'true') {
        return true
      } else {
        return false
      }
    }
  }
}

function hasFeatureFlag(setting) {
  for (let key in Store.getters['authentification/getSettings']) {
    if (Store.getters['authentification/getSettings'][key].type == setting) {
      if (Store.getters['authentification/getSettings'][key].active) {
        return true
      } else {
        return false
      }
    }
  }
}

function hasRole(store, roles) {
  if (store.state.authentification.roles.find(role => role == 'Super Admin')) return true
  if (typeof roles === 'string') {
    if (store.state.authentification.roles.find(role => role == roles)) return true
  } else if (typeof roles === 'object') {
    for (const role of roles) {
      if (store.state.authentification.roles.find(userRole => userRole == role)) return true
    }
  }
  return false
}

function colorCodeVersionBox(version) {
  const [majorA, minorA, patchA] = version.split('.').map(Number)
  const [majorB, minorB, patchB] = app.boxversion.split('.').map(Number)

  if (majorA !== majorB) {
    return '#ff0000' //  major
  } else if (minorA !== minorB) {
    return '#ff6a00' //  minor
  } else if (patchA !== patchB) {
    return '#ffa200' //  patch
  } else {
    return '#09ab3c' //  full
  }
}

function showLastName() {
  return Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_LASTNAME') &&
    Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_LASTNAME').value == 'true'
    ? true
    : false
}

function showFirstname() {
  return Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_FIRSTNAME') &&
    Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_FIRSTNAME').value ==
      'true'
    ? true
    : false
}

function showAgeOrBirthdate() {
  return (
    Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_BIRTHDATE_TYPE') &&
    Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_BIRTHDATE_TYPE').value
  )
}

function showPatientNumber() {
  return Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_CLIENT_NUMBER') &&
    Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_CLIENT_NUMBER').value ==
      'true'
    ? true
    : false
}

function showPhone() {
  return Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_PHONE') &&
    Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_PHONE').value == 'true'
    ? true
    : false
}

function showInitials() {
  return Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_INITIALS') &&
    Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_INITIALS').value == 'true'
    ? true
    : false
}

function showFileNumber() {
  return Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_FILE_NUMBER') &&
    Object.values(Store.getters['authentification/getSettings']).find(el => el.type == 'UP_PI_FILE_NUMBER').value ==
      'true'
    ? true
    : false
}

function hasExamPref(setting) {
  for (let key in Store.getters['authentification/getExamPreferences']) {
    if (getExamPreferencesKey(Store.getters['authentification/getExamPreferences'][key].type) == setting) {
      if (Store.getters['authentification/getExamPreferences'][key].value == 2) {
        return true
      } else {
        return false
      }
    }
  }
}

function hasFF(setting) {
  for (let key in Store.getters['authentification/getFeaturesFlag']) {
    if (getFeaturesFlagKey(Store.getters['authentification/getFeaturesFlag'][key].type) == setting) {
      if (Store.getters['authentification/getFeaturesFlag'][key].value == true) {
        return true
      } else {
        return false
      }
    }
  }
}
