import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import authentification from './modules/authentification'
import utils from './modules/utils'
import tools from './modules/tools'
import vueTableFilters from './modules/vueTableFilters'
import organizations from './modules/organizations'
import shop from './modules/shop'
import publicFiles from './modules/publicFiles'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      reducer: state => ({
        authentification: state.authentification,
        tools: {
          sidebarShow: state.tools.sidebarShow,
          sidebarMinimize: state.tools.sidebarMinimize,
          darkMode: state.tools.darkMode
        },
        utils: {
          nav: state.utils.nav,
          access: state.utils.access
        }
      })
    })
  ],
  modules: {
    tools,
    authentification,
    utils,
    vueTableFilters,
    organizations,
    shop,
    publicFiles
  },
  actions: {
    resetState({ commit }) {
      commit('authentification/resetState')
    }
  }
})

export default store
