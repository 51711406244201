const getDefaultState = () => {
  return {
    version: '',
  }
}
  
const state = getDefaultState();

const mutations = {
    setVersion(state, version) { 
        state.version = version; 
    }
}
  
  export default {
    namespaced: true,
    state,
    mutations
  }
  